import { Visibility } from "@material-ui/icons"
import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import {
  OfferButton,
  OfferButtonWrapper,
  OfferDesc,
  OfferDescWrapper,
  OfferImage,
  OfferItem,
  OfferItemTitle,
  OfferSection,
  OfferText,
  OfferTitle,
  OfferWrapper,
} from "./OffersStyles"

const Offer = props => {
  const data = useStaticQuery(graphql`
    query Kolonie {
      allWpPage(filter: { title: { eq: "Home" } }) {
        nodes {
          AcfHome {
            offerText
          }
        }
      }
      kolonie: allWpCamp(sort: { fields: ACF_kolonie___order }) {
        nodes {
          title
          uri
          season {
            nodes {
              slug
            }
          }
          ACF_kolonie {
            visibility
            cena
            shortDesc
            termin
            numberPlaces
            city
            fakultet
          }
          featuredImage {
            node {
              localFile {
                extension
                publicURL
              }
            }
          }
        }
      }
    }
  `)

  console.log(data.kolonie.nodes)

  return (
    <OfferSection id="kolonie">
      {/* <OfferTitle>Zimowiska</OfferTitle> */}
      {/* <OfferWrapper>
        <OfferText dangerouslySetInnerHTML={{ __html: ACFOfferText }} />
        {data.kolonie.nodes.map(post =>
          post.ACF_kolonie.visibility ? (
            post.season.nodes[0].slug === "zima" ? (
              <OfferItem key={post.title}>
                {post.featuredImage ? (
                  <OfferImage>
                    <img
                      alt="zdjęcie kolonii"
                      src={post.featuredImage.node.localFile.publicURL}
                    />
                  </OfferImage>
                ) : (
                  <OfferImage></OfferImage>
                )}
                <OfferDesc>
                  <OfferDescWrapper>
                    <OfferItemTitle key={post.uri.replace("kolonie/", "")}>
                      {post.title}{" "}
                    </OfferItemTitle>
                    <div className="OfferPrice">
                      <span>cena: </span>
                      <span className="price">{post.ACF_kolonie.cena}</span>
                    </div>

                    <div className="OfferDate">
                      <span>
                        termin: <b>{post.ACF_kolonie.termin}</b>
                      </span>
                      <span>
                        miejsce: <b>{post.ACF_kolonie.city}</b>
                      </span>
                      {post.ACF_kolonie.fakultet ? (
                        <span>
                          {" "}
                          fakultet: <b>{post.ACF_kolonie.fakultet}</b>
                        </span>
                      ) : (
                        <div style={{ visibility: "hidden" }}>fakultet</div>
                      )}
                    </div>
                  </OfferDescWrapper>

                  <OfferButtonWrapper>
                    <Link to={post.uri.replace("kolonie/", "")}>
                      <OfferButton>Więcej</OfferButton>
                    </Link>
                  </OfferButtonWrapper>
                </OfferDesc>
              </OfferItem>
            ) : null
          ) : null
        )}
      </OfferWrapper> */}
      <OfferTitle>Kolonie letnie</OfferTitle>
      <OfferWrapper>
        {data.kolonie.nodes.map(post =>
          post.ACF_kolonie.visibility ? (
            post.season.nodes[0].slug === "lato" ? (
              <OfferItem key={post.title}>
                {post.featuredImage ? (
                  <OfferImage>
                    <img
                      alt="zdjęcie kolonii"
                      src={post.featuredImage.node.localFile.publicURL}
                    />
                  </OfferImage>
                ) : (
                  <OfferImage></OfferImage>
                )}
                <OfferDesc>
                  <OfferDescWrapper>
                    <OfferItemTitle key={post.uri.replace("kolonie/", "")}>
                      {post.title}{" "}
                    </OfferItemTitle>
                    <div className="OfferPrice">
                      <span>cena: </span>
                      <span className="price">{post.ACF_kolonie.cena}</span>
                    </div>

                    <div className="OfferDate">
                      <span>
                        termin: <b>{post.ACF_kolonie.termin}</b>
                      </span>
                      <span>
                        miejsce: <b>{post.ACF_kolonie.city}</b>
                      </span>
                      {post.ACF_kolonie.fakultet ? (
                        <span>
                          {" "}
                          fakultet: <b>{post.ACF_kolonie.fakultet}</b>
                        </span>
                      ) : (
                        <div style={{ visibility: "hidden" }}>fakultet</div>
                      )}
                    </div>
                  </OfferDescWrapper>

                  <OfferButtonWrapper>
                    <Link to={post.uri.replace("kolonie/", "")}>
                      <OfferButton>Więcej</OfferButton>
                    </Link>
                  </OfferButtonWrapper>
                </OfferDesc>
              </OfferItem>
            ) : null
          ) : null
        )}
      </OfferWrapper>
    </OfferSection>
  )
}

export default Offer
