import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
`

const PopupBox = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 1.2rem;
  font-family: ${props => props.theme.fonts.secondary};
  font-weight: 500;
`
const TextBox = styled.div`
  font-size: 1.1rem;
  font-family: ${props => props.theme.fonts.secondary};
  font-weight: 400;
  color: ${props => props.theme.colors.primary};
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  grid-gap: 10px;
  padding: 2% 10%;

  p {
    margin-bottom: 4px;
  }
  a {
    color: ${props => props.theme.colors.secondary};
  }
`

const TitleBox = styled.h2`
  font-size: 2rem;
  font-family: ${props => props.theme.fonts.secondary};
  font-weight: 700;
  color: ${props => props.theme.colors.primary};
  text-align: center;
`

const ButtonBox = styled.div`
  margin: 1rem 0;
  text-align: center;
`

const CloseButton = styled.button`
  font-size: 14px;
  letter-spacing: 0.15em;
  font-family: ${props => props.theme.fonts.secondary};
  display: inline-block;
  height: 30px;
  line-height: 6px;
  padding: 20px;
  color: white;
  -webkit-transition: all 200ms;
  transition: all 200ms;
  position: relative;
  background: ${props => props.theme.colors.secondary};
  border-radius: 5px;
  display: inline-block;
  border: none;
  cursor: pointer;
  transition: all 0.4s ease 0s;

  &:hover {
    background: ${props => props.theme.colors.primary};
    letter-spacing: 0.4em;
    transition: all 0.4s ease 0s;
  }
`

const Popup = () => {
  const [isOpen, setIsOpen] = useState(false)
  const popupRef = useRef(null)

  useEffect(() => {
    const popupClosed = document.cookie.includes("popupClosed=true")
    if (!popupClosed) {
      const timer = setTimeout(() => {
        setIsOpen(true)
      }, 2000)
      return () => clearTimeout(timer)
    }
  }, [])

  useEffect(() => {
    function handleClickOutside(event) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        closePopup()
      }
    }

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside)
    } else {
      document.removeEventListener("mousedown", handleClickOutside)
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [isOpen])

  const closePopup = () => {
    document.cookie = "popupClosed=true; path=/"
    setIsOpen(false)
  }

  return (
    <>
      {isOpen && (
        <Overlay>
          <PopupBox ref={popupRef}>
            <TitleBox>Problemy techniczne</TitleBox>
            <TextBox>
              <p>
                Mogą wystąpić problemy techniczne z formularzem zapisania na
                kolonie
              </p>
              <p>W razie występowania błędu, napisz na adres email:</p>
              <a href="mailto: aventura.kontakt@gmail.com">
                aventura.kontakt@gmail.com
              </a>
            </TextBox>
            <ButtonBox>
              <CloseButton onClick={() => setIsOpen(false)}>
                Zamknij
              </CloseButton>
            </ButtonBox>
          </PopupBox>
        </Overlay>
      )}
    </>
  )
}

export default Popup
